<template>
  <div class="skills container">
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="mb-5 text-title">{{ $t('page.skills') }}</h1>
        </div>
        <div class="col-12 row justify-content-center">
          <div class="col">
            <h2 class="mb-3 text-caption">{{ $t('skills.front-end.title') }}</h2>
            <p>{{ $t('skills.front-end.content') }}</p>
          </div>
          <div class="col">
            <h2 class="mb-3 text-caption">{{ $t('skills.back-end.title') }}</h2>
            <p>{{ $t('skills.back-end.content') }}</p>
          </div>
          <div class="col">
            <h2 class="mb-3 text-caption">{{ $t('skills.design.title') }}</h2>
            <p>{{ $t('skills.design.content') }}</p>
          </div>
        </div>
        <div class="col">
          <h1 class="mb-3 text-title">{{ $t('languagesAndTools') }}:</h1>
        </div>
        <div class="col-12 row">
          <div class="col-auto skill-items">
            <i class="lab la-html5"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-css3-alt"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-js-square"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-vuejs"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-react"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-bootstrap"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-sass"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-git"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-github"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-gitlab"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-java"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-python"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-node-js"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-npm"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-yarn"></i>
          </div>
          <div class="col-auto skill-items">
            <i class="lab la-figma"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeBackground } from "@/assets/js/background.js";
export default {
  name: 'Skills',
  created() {
    changeBackground("skills");
  }
}
</script>